import React from 'react';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import styled from "styled-components";
import { QuesstAdminLogo } from 'src/utils/constants'
import { themeVars } from 'src/utils/common'
import Loader from '.';

const SplashContainer = styled.div`
  height: 100%;
  background-color: ${themeVars.extraExtraLightGrey};
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SplashImage = styled.img`
  height: 40px;
`;
const LoaderContainer = styled.div`
  margin-top: 10px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: themeVars.extraExtraLightGrey,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  }
}));

const SplashScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box width={400}>
        <SplashContainer>
          <SplashImage draggable={false} src={QuesstAdminLogo} />
          <LoaderContainer>
            <Loader sizeSpeed="medium-fast" color={themeVars.mediumGrey} />
          </LoaderContainer>
        </SplashContainer>
      </Box>
    </div>
  );
}

export default SplashScreen;
