import React from 'react'
import styled from "styled-components";
import Loader from "./index";
import { themeVars } from 'src/utils/common'

const SplashContainer = styled.div`
  height: 100%;
  // background-color: ${themeVars.extraExtraLightGrey};
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SplashImage = styled.img`
  height: 40px;
`;

const LoaderContainer = styled.div`
  margin-top: 10px;
`;
export default function SplashLoader(props) {
  return (
    <SplashContainer>
      <LoaderContainer>
        <Loader sizeSpeed="medium-fast" color={themeVars.adminBlue} />
      </LoaderContainer>
    </SplashContainer>
  );
}
