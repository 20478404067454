import axios from 'axios'
import { API_URL } from 'src/config/default'

import createAxios from "./createAxios";
const browserAxios = createAxios();

//auth

export const getAllUserDetail = async (page, size, oderFiled, orderBy, apiEndPoint,id,searchFileds,searchKeyword, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
    params: { page: page, size: size, oderFiled: oderFiled, orderBy: orderBy,id:id,searchFileds:searchFileds,searchKeyword:searchKeyword },
  });
}

export const getCmsPageDetail = async (apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
  });
}

export const editProfileDetail = async (data,id, contact_no, email, name, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}?id=${id}&email=${email}&contact_no=${contact_no}&name=${name}`,
    data,
  });
}

export const ChangePasswordAdmin = async (id, old_password, new_password, apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}?id=${id}&old_password=${old_password}&new_password=${new_password}`,
  });
}


export const getAppVersionDetail = async (apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
  });
}
export const getDeshboardDetail = async (axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/web/dashboard`,
  });
}
export const getRecordByIdDetail = async (page, size, oderFiled, orderBy, userId, apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
    params: { page: page, size: size, oderFiled: oderFiled, orderBy: orderBy, userId: userId },
  });
}

export const deleteUser = async (id, apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
    params: { id: id },
  });
}
export const active_deactiveUser = async (id, status, apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
    params: { id: id, status: status },
  });
}

export const getCategoryList = async (apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
  });
}

export const getCountryList = async (apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/api/getCountryList?fieldname=${apiEndPoint}`,
  });
}

export const addConsultants = async (data, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const sendNotifications = async (data, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const editConsultantDetail = async (data, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const updateAppVersion = async (data, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const updatePaymentgetway = async (data,apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const updateCmsPageDetail = async (data, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const userLogin = async (data, password, axios = browserAxios) => {
  return await axios.get({ url: `${API_URL}/web/login?email=${data}&password=${password}` });
};

export const getLoggedInUserDetails = (id, token, axios = browserAxios) => {
  return axios.get({
    url: `${API_URL}/web/loggedInUserDetails?id=${id}&token=${token}`,
  });
};

export const userLogout = (id, axios = browserAxios) => {
  return axios.get({
    url: `${API_URL}/web/userLogout?id=${id}`,
  });
};

export const getUser = async (axios = browserAxios) => {
  const res = await axios.get({ url: `${API_URL}/api/auth/me` });
  return res;
}

export const verifyEmail = async (email, axios = browserAxios) => {
  return await axios.get({ url: `${API_URL}/web/verifyEmail?email=${email}` });
}

export const getLanguages = async (axios = browserAxios) => {
  return await axios.get({ url: `${API_URL}/api/language/languages` });
}

export const getLanguage = async (id, axios = browserAxios) => {
  return await axios.get({ url: `${API_URL}/api/language/language-links?id=${id}` });
}

export const totalCommission = async (apiEndPoint,id,axios = browserAxios) => {
  return await axios.get({ url: `${API_URL}/${apiEndPoint}?${id}` });
}

export const getPaymentgetway = async (apiEndPoint,axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
  });
}

export const getBankDetail = async (apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
  });
}

export const getConsultantList = async (fieldName,apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}?fieldname=${fieldName}`,
  });
}

export const addPayInfluncer = async (data, apiEndPoint, axios = browserAxios) => {
  console.log('-data----------------',data,apiEndPoint)
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}

export const countryFilter = async (page, size, oderFiled, orderBy,id,apiEndPoint, axios = browserAxios) => {
  return await axios.get({
    url: `${API_URL}/${apiEndPoint}`,
    params:{page: page, size: size, oderFiled: oderFiled, orderBy: orderBy, id: id }
  });
}

export const editUserDetail = async (data, apiEndPoint, axios = browserAxios) => {
  return await axios.post({
    url: `${API_URL}/${apiEndPoint}`,
    data,
  })
}