import React from 'react'
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiLockReset,
  mdiTicketAccount,
  mdiHelpCircleOutline,
  mdiPower,
  mdiAlertCircleOutline,
  mdiSecurity,
  mdiBell,
  mdiEmail,
  mdiMapMarker,
  mdiCardTextOutline,
  mdiBellOutline,
  mdiEmailSearchOutline,
  mdiCommentSearchOutline,
  mdiBookSearchOutline,
  mdiHeartMultipleOutline,
  mdiRss,
  mdiArchive,
  mdiClose,
  mdiInformationOutline,
  mdiPlusBoxOutline,
  mdiMinusBoxOutline,
  mdiDownload,
  mdiLockOutline,
  mdiSendCircleOutline,
  mdiTimerSand,
  mdiAlertCircle,
  mdiCheckboxBlankCircle,
  mdiThumbUp,
  mdiHeartMultiple,
  mdiCardText,
  mdiForum,
  mdiPhoneRotatePortrait,
  mdiChevronRight,
  mdiChevronLeft,
  mdiDeleteForeverOutline,
  mdiContentSaveAlertOutline,
  mdiClockEnd,
  mdiCheckCircleOutline,
  mdiLeadPencil,
  mdiDelete,
  mdiCheck,
  mdiFrequentlyAskedQuestions,
  mdiHeartCircle,
  mdiAccountCircle,
  mdiSortAscending,
  mdiSortDescending,
  mdiFilterOutline,
  mdiPencilRemove,
  mdiClockOutline,
  mdiEmailMarkAsUnread,
  mdiEmailOpen,
  mdiAccountEdit,
  mdiCheckCircle,
  mdiRefreshCircle,
  mdiRefresh,
  mdiCogOutline,
  mdiViewColumn,
  mdiFilterVariant,
  mdiCloudUploadOutline,
  mdiFormatListNumbered,
  mdiTableColumnWidth,
  mdiPencil,
  mdiFolderOpen,
  mdiEyeOutline,
  mdiEye,
  mdiEyeOff,
  mdiPrinter,
  mdiEyeOffOutline,
  mdiCardsVariant,
  mdiCloudDownload,
  mdiRepeat,
  mdiCloudSyncOutline,
  mdiCommentEyeOutline,
  mdiCalendarMonth,
  mdiCommentEditOutline,
  mdiDomain,
  mdiBriefcaseOutline,
  mdiAccountSwitch,
  mdiMagnify,
  mdiCloseCircleOutline,
  mdiSearchWeb,
  mdiCommentQuestionOutline,
  mdiCommentTextOutline,
  mdiPinOutline,
  mdiAccountOutline,
  mdiCardSearch,
  mdiShieldStar,
  mdiLayersSearch,
  mdiBellAlert,
  mdiExitRun,
  mdiPencilOffOutline,
  mdiLayersSearchOutline,
  mdiImageOutline,
  mdiEraser,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiPinOffOutline,
  mdiFilter,
  mdiArrowLeft,
  mdiEmailOutline,
  mdiTwitter,
  mdiYoutube,
  mdiLinkVariant,
  mdiOpenInNew,
  mdiChevronDown,
  mdiVimeo,
  mdiInstagram,
  mdiDragHorizontalVariant,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiPostOutline,
  mdiBookshelf,
  mdiGoogleCirclesCommunities,
  mdiAccountClock,
  mdiCellphoneLink,
  mdiTranslate,
  mdiPlaylistRemove,
  mdiRotate3dVariant,
  mdiArrowExpand,
  mdiUpload,
  mdiArrowCollapse,
  mdiPageLast,
  mdiPageFirst,
  mdiTableCheck,
  mdiTableLargeRemove,
  mdiPlusThick,
  mdiCurrencyUsd,
  mdiAccountGroupOutline,
  mdiCalendarMonthOutline,
  mdiCircleEditOutline,
  mdiSendCircle
} from '@mdi/js'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMediumM,
  faDribbble,
  faBehance,
  faLinkedinIn,
  faFacebookF,
  faPinterestP,
  faTiktok,
  faGithub,
  faSnapchatGhost,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { themeVars } from '../../utils/common'

const mdiBellRemove =
  "M20.12 14.46L18 16.59L15.88 14.46L14.46 15.88L16.59 18L14.46 20.12L15.88 21.54L18 19.41L20.12 21.54L21.54 20.12L19.41 18L21.54 15.88M12 2C10.9 2 10 2.9 10 4C10 4.1 10 4.19 10 4.29C7.12 5.14 5 7.82 5 11V17L3 19V20H12.35C12.12 19.36 12 18.68 12 18C12 14.69 14.69 12 18 12C18.34 12 18.67 12.03 19 12.09V11C19 7.82 16.88 5.14 14 4.29C14 4.19 14 4.1 14 4C14 2.9 13.11 2 12 2M10 21C10 22.11 10.9 23 12 23C12.66 23 13.28 22.67 13.65 22.13C13.33 21.79 13.05 21.41 12.81 21Z";

const defaultProps = {
  color: themeVars.mediumGrey,
  size: 1
};

const getIconByType = (type, customProps) => {
  const props = { ...defaultProps, ...customProps };
  switch (type) {
    case "mdiSendCircle":
      return <Icon path={mdiSendCircle} {...props}></Icon>;
    case "mdiCircleEditOutline":
      return <Icon path={mdiCircleEditOutline} {...props}></Icon>;
    case "mdiCalendarMonthOutline":
      return <Icon path={mdiCalendarMonthOutline} {...props}></Icon>;
    case "mdiCurrencyUsd":
      return <Icon path={mdiCurrencyUsd} {...props}></Icon>;
    case "mdiAccountGroupOutline":
      return <Icon path={mdiAccountGroupOutline} {...props}></Icon>;
    case "mdiEye":
      return <Icon path={mdiEye} {...props}></Icon>;
    case "mdiPlusThick":
      return <Icon path={mdiPlusThick} {...props}></Icon>;
    case "mdiTableCheck":
      return <Icon path={mdiTableCheck} {...props}></Icon>;
    case "mdiTableLargeRemove":
      return <Icon path={mdiTableLargeRemove} {...props}></Icon>;
    case "mdiAccountMultiple":
      return <Icon path={mdiAccountMultiple} {...props}></Icon>;
    case "mdiPageFirst":
      return <Icon path={mdiPageFirst} {...props}></Icon>;
    case "mdiPageLast":
      return <Icon path={mdiPageLast} {...props}></Icon>;
    case "mdiChevronDown":
      return <Icon path={mdiChevronDown} {...props}></Icon>;
    case "mdiArrowCollapse":
      return <Icon path={mdiArrowCollapse} {...props}></Icon>;
    case "mdiArrowExpand":
      return <Icon path={mdiArrowExpand} {...props}></Icon>;
    case "mdiPostOutline":
      return <Icon path={mdiPostOutline} {...props}></Icon>;
    case "mdiBookshelf":
      return <Icon path={mdiBookshelf} {...props}></Icon>;
    case "mdiGoogleCirclesCommunities":
      return <Icon path={mdiGoogleCirclesCommunities} {...props}></Icon>;
    case "mdiAccountClock":
      return <Icon path={mdiAccountClock} {...props}></Icon>;
    case "mdiCellphoneLink":
      return <Icon path={mdiCellphoneLink} {...props}></Icon>;
    case "mdiTranslate":
      return <Icon path={mdiTranslate} {...props}></Icon>;
    case "mdiPlaylistRemove":
      return <Icon path={mdiPlaylistRemove} {...props}></Icon>;
    case "mdiAccount":
      return <Icon path={mdiAccount} {...props}></Icon>;
    case "mdiLockReset":
      return <Icon path={mdiLockReset} {...props}></Icon>;
    case "mdiTicketAccount":
      return <Icon path={mdiTicketAccount} {...props}></Icon>;
    case "mdiHelpCircleOutline":
      return <Icon path={mdiHelpCircleOutline} {...props}></Icon>;
    case "mdiPower":
      return <Icon path={mdiPower} {...props}></Icon>;
    case "mdiAlertCircleOutline":
      return <Icon path={mdiAlertCircleOutline} {...props}></Icon>;
    case "mdiSecurity":
      return <Icon path={mdiSecurity} {...props}></Icon>;
    case "mdiEmail":
      return <Icon path={mdiEmail} {...props}></Icon>;
    case "mdiBell":
      return <Icon path={mdiBell} {...props}></Icon>;
    case "mdiMapMarker":
      return <Icon path={mdiMapMarker} {...props}></Icon>;
    case "mdiCardTextOutline":
      return <Icon path={mdiCardTextOutline} {...props}></Icon>;
    case "mdiBellOutline":
      return <Icon path={mdiBellOutline} {...props}></Icon>;
    case "mdiEmailSearchOutline":
      return <Icon path={mdiEmailSearchOutline} {...props}></Icon>;
    case "mdiCommentSearchOutline":
      return <Icon path={mdiCommentSearchOutline} {...props}></Icon>;
    case "mdiBookSearchOutline":
      return <Icon path={mdiBookSearchOutline} {...props}></Icon>;
    case "mdiHeartMultipleOutline":
      return <Icon path={mdiHeartMultipleOutline} {...props}></Icon>;
    case "mdiRss":
      return <Icon path={mdiRss} {...props}></Icon>;
    case "mdiArchive":
      return <Icon path={mdiArchive} {...props}></Icon>;
    case "mdiClose":
      return <Icon path={mdiClose} {...props}></Icon>;
    case "mdiInformationOutline":
      return <Icon path={mdiInformationOutline} {...props}></Icon>;
    case "mdiPlusBoxOutline":
      return <Icon path={mdiPlusBoxOutline} {...props}></Icon>;
    case "mdiMinusBoxOutline":
      return <Icon path={mdiMinusBoxOutline} {...props}></Icon>;
    case "mdiDownload":
      return <Icon path={mdiDownload} {...props}></Icon>;
    case "mdiLockOutline":
      return <Icon path={mdiLockOutline} {...props}></Icon>;
    case "mdiSendCircleOutline":
      return <Icon path={mdiSendCircleOutline} {...props}></Icon>;
    case "mdiTimerSand":
      return <Icon path={mdiTimerSand} {...props}></Icon>;
    case "mdiAlertCircle":
      return <Icon path={mdiAlertCircle} {...props}></Icon>;
    case "mdiCheckboxBlankCircle":
      return <Icon path={mdiCheckboxBlankCircle} {...props}></Icon>;
    case "mdiThumbUp":
      return <Icon path={mdiThumbUp} {...props}></Icon>;
    case "mdiHeartMultiple":
      return <Icon path={mdiHeartMultiple} {...props}></Icon>;
    case "mdiCardText":
      return <Icon path={mdiCardText} {...props}></Icon>;
    case "mdiForum":
      return <Icon path={mdiForum} {...props}></Icon>;
    case "mdiPhoneRotatePortrait":
      return <Icon path={mdiPhoneRotatePortrait} {...props}></Icon>;
    case "mdiChevronRight":
      return <Icon path={mdiChevronRight} {...props}></Icon>;
    case "mdiChevronLeft":
      return <Icon path={mdiChevronLeft} {...props}></Icon>;
    case "mdiDeleteForeverOutline":
      return <Icon path={mdiDeleteForeverOutline} {...props}></Icon>;
    case "mdiContentSaveAlertOutline":
      return <Icon path={mdiContentSaveAlertOutline} {...props}></Icon>;
    case "mdiClockEnd":
      return <Icon path={mdiClockEnd} {...props}></Icon>;
    case "mdiCheckCircleOutline":
      return <Icon path={mdiCheckCircleOutline} {...props}></Icon>;
    case "mdiLeadPencil":
      return <Icon path={mdiLeadPencil} {...props}></Icon>;
    case "mdiDelete":
      return <Icon path={mdiDelete} {...props}></Icon>;
    case "mdiCheck":
      return <Icon path={mdiCheck} {...props}></Icon>;
    case "mdiFrequentlyAskedQuestions":
      return <Icon path={mdiFrequentlyAskedQuestions} {...props}></Icon>;
    case "mdiHeartCircle":
      return <Icon path={mdiHeartCircle} {...props}></Icon>;
    case "mdiAccountCircle":
      return <Icon path={mdiAccountCircle} {...props}></Icon>;
    case "mdiSortAscending":
      return <Icon path={mdiSortAscending} {...props}></Icon>;
    case "mdiSortDescending":
      return <Icon path={mdiSortDescending} {...props}></Icon>;
    case "mdiFilterOutline":
      return <Icon path={mdiFilterOutline} {...props}></Icon>;
    case "mdiPencilRemove":
      return <Icon path={mdiPencilRemove} {...props}></Icon>;
    case "mdiClockOutline":
      return <Icon path={mdiClockOutline} {...props}></Icon>;
    case "mdiEmailMarkAsUnread":
      return <Icon path={mdiEmailMarkAsUnread} {...props}></Icon>;
    case "mdiEmailOpen":
      return <Icon path={mdiEmailOpen} {...props}></Icon>;
    case "mdiAccountEdit":
      return <Icon path={mdiAccountEdit} {...props}></Icon>;
    case "mdiCheckCircle":
      return <Icon path={mdiCheckCircle} {...props}></Icon>;
    case "mdiRefreshCircle":
      return <Icon path={mdiRefreshCircle} {...props}></Icon>;
    case "mdiRefresh":
      return <Icon path={mdiRefresh} {...props}></Icon>;
    case "mdiCloudDownload":
      return <Icon path={mdiCloudDownload} {...props}></Icon>;
    case "mdiCogOutline":
      return <Icon path={mdiCogOutline} {...props}></Icon>;
    case "mdiViewColumn":
      return <Icon path={mdiViewColumn} {...props}></Icon>;
    case "mdiFormatListNumbered":
      return <Icon path={mdiFormatListNumbered} {...props}></Icon>;
    case "mdiFilterVariant":
      return <Icon path={mdiFilterVariant} {...props}></Icon>;
    case "mdiCloudUploadOutline":
      return <Icon path={mdiCloudUploadOutline} {...props}></Icon>;
    case "mdiTableColumnWidth":
      return <Icon path={mdiTableColumnWidth} {...props}></Icon>;
    case "mdiPencil":
      return <Icon path={mdiPencil} {...props}></Icon>;
    case "mdiPrinter":
      return <Icon path={mdiPrinter} {...props}></Icon>;
    case "mdiFolderOpen":
      return <Icon path={mdiFolderOpen} {...props}></Icon>;
    case "mdiEyeOutline":
      return <Icon path={mdiEyeOutline} {...props}></Icon>;
    case "mdiEyeOff":
      return <Icon path={mdiEyeOff} {...props}></Icon>;
    case "mdiEyeOffOutline":
      return <Icon path={mdiEyeOffOutline} {...props}></Icon>;
    case "mdiCardsVariant":
      return <Icon path={mdiCardsVariant} {...props}></Icon>;
    case "mdiRepeat":
      return <Icon path={mdiRepeat} {...props}></Icon>;
    case "mdiCloudSyncOutline":
      return <Icon path={mdiCloudSyncOutline} {...props}></Icon>;
    case "mdiCommentEyeOutline":
      return <Icon path={mdiCommentEyeOutline} {...props}></Icon>;
    case "mdiCalendarMonth":
      return <Icon path={mdiCalendarMonth} {...props}></Icon>;
    case "mdiCommentEditOutline":
      return <Icon path={mdiCommentEditOutline} {...props}></Icon>;
    case "mdiDomain":
      return <Icon path={mdiDomain} {...props}></Icon>;
    case "mdiBriefcaseOutline":
      return <Icon path={mdiBriefcaseOutline} {...props}></Icon>;
    case "mdiAccountSwitch":
      return <Icon path={mdiAccountSwitch} {...props}></Icon>;
    case "mdiMagnify":
      return <Icon path={mdiMagnify} {...props}></Icon>;
    case "mdiCloseCircleOutline":
      return <Icon path={mdiCloseCircleOutline} {...props}></Icon>;
    case "mdiSearchWeb":
      return <Icon path={mdiSearchWeb} {...props}></Icon>;
    case "mdiCommentQuestionOutline":
      return <Icon path={mdiCommentQuestionOutline} {...props}></Icon>;
    case "mdiCommentTextOutline":
      return <Icon path={mdiCommentTextOutline} {...props}></Icon>;
    case "mdiPinOutline":
      return <Icon path={mdiPinOutline} {...props}></Icon>;
    case "mdiAccountOutline":
      return <Icon path={mdiAccountOutline} {...props}></Icon>;
    case "mdiCardSearch":
      return <Icon path={mdiCardSearch} {...props}></Icon>;
    case "mdiShieldStar":
      return <Icon path={mdiShieldStar} {...props}></Icon>;
    case "mdiBellRemove":
      return <Icon path={mdiBellRemove} {...props}></Icon>;
    case "mdiLayersSearch":
      return <Icon path={mdiLayersSearch} {...props}></Icon>;
    case "mdiBellAlert":
      return <Icon path={mdiBellAlert} {...props}></Icon>;
    case "mdiExitRun":
      return <Icon path={mdiExitRun} {...props}></Icon>;
    case "mdiPencilOffOutline":
      return <Icon path={mdiPencilOffOutline} {...props}></Icon>;
    case "mdiLayersSearchOutline":
      return <Icon path={mdiLayersSearchOutline} {...props}></Icon>;
    case "mdiImageOutline":
      return <Icon path={mdiImageOutline} {...props}></Icon>;
    case "mdiEraser":
      return <Icon path={mdiEraser} {...props}></Icon>;
    case "mdiFormatAlignRight":
      return <Icon path={mdiFormatAlignRight} {...props}></Icon>;
    case "mdiFormatAlignLeft":
      return <Icon path={mdiFormatAlignLeft} {...props}></Icon>;
    case "mdiPinOffOutline":
      return <Icon path={mdiPinOffOutline} {...props}></Icon>;
    case "mdiFilter":
      return <Icon path={mdiFilter} {...props}></Icon>;
    case "mdiArrowLeft":
      return <Icon path={mdiArrowLeft} {...props}></Icon>;
    case "mdiEmailOutline":
      return <Icon path={mdiEmailOutline} {...props}></Icon>;
    case "mdiTwitter":
      return <Icon path={mdiTwitter} {...props}></Icon>;
    case "mdiYoutube":
      return <Icon path={mdiYoutube} {...props}></Icon>;
    case "mdiLinkVariant":
      return <Icon path={mdiLinkVariant} {...props}></Icon>;
    case "mdiOpenInNew":
      return <Icon path={mdiOpenInNew} {...props}></Icon>;

    case "mdiVimeo":
      return <Icon path={mdiVimeo} {...props}></Icon>;
    case "mdiInstagram":
      return <Icon path={mdiInstagram} {...props}></Icon>;

    case "mdiDragHorizontalVariant":
      return <Icon path={mdiDragHorizontalVariant} {...props}></Icon>;
    case "mdiPlus":
      return <Icon path={mdiPlus} {...props}></Icon>;
    case "mdiPlusCircleOutline":
      return <Icon path={mdiPlusCircleOutline} {...props}></Icon>;
    case "faMediumM":
      return (
        <FontAwesomeIcon color={customProps.color} icon={faMediumM} size="1x" />
      );
    case "faDribbble":
      return (
        <FontAwesomeIcon
          color={customProps.color}
          icon={faDribbble}
          size="1x"
        />
      );
    case "faBehance":
      return (
        <FontAwesomeIcon color={customProps.color} icon={faBehance} size="1x" />
      );
    case "faLinkedinIn":
      return (
        <FontAwesomeIcon
          color={customProps.color}
          icon={faLinkedinIn}
          size="1x"
        />
      );
    case "faFacebookF":
      return (
        <FontAwesomeIcon
          color={customProps.color}
          icon={faFacebookF}
          size="1x"
        />
      );
    case "faPinterestP":
      return (
        <FontAwesomeIcon
          color={customProps.color}
          icon={faPinterestP}
          size="1x"
        />
      );
    case "faTiktok":
      return (
        <FontAwesomeIcon color={customProps.color} icon={faTiktok} size="1x" />
      );
    case "faSnapchatGhost":
      return (
        <FontAwesomeIcon
          color={customProps.color}
          icon={faSnapchatGhost}
          size="1x"
        />
      );
    case "faGithub":
      return (
        <FontAwesomeIcon color={customProps.color} icon={faGithub} size="1x" />
      );
    case "faWhatsapp":
      return (
        <FontAwesomeIcon
          color={customProps.color}
          icon={faWhatsapp}
          size="1x"
        />
      );
    case "mdiDotsHorizontal":
      return <Icon path={mdiDotsHorizontal} {...props}></Icon>;
    case "mdiDotsVertical":
      return <Icon path={mdiDotsVertical} {...props}></Icon>;
    default: return;
    case "mdiRotate3dVariant":
      return <Icon path={mdiRotate3dVariant} {...props}></Icon>
    case "mdiUpload":
      return <Icon path={mdiUpload} {...props}></Icon>
  }
};
export default getIconByType;
