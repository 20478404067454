import * as actionTypes from "./actionTypes";

export const actions = {
  setSnackbar: data => ({
    type: actionTypes.SNACKBAR_DATA,
    data
  }),
};

export const initialState = {
  snackbar: { open: false, severity: "", message: "" },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SNACKBAR_DATA:
      return {
        ...state,
        snackbar: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
