import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import SplashLoader from './components/loader/SplashLoader';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<SplashLoader/>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    auth: 'authname',
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: () => <Redirect to="/dashboard/basic" />
      }, 
      {
        exact: true,
        path: '/dashboard/basic',
        component: lazy(() => import('src/views/dashboard/Basic Metrics'))
      },
      {
        exact: true,
        path: '/profile',
        component: lazy(() => import('src/views/profile'))
      },
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('src/views/user'))
      },
      {
        exact: true,
        path: '/call',
        component: lazy(() => import('src/views/call'))
      },
      {
        exact: true,
        path: '/shoutout',
        component: lazy(() => import('src/views/doneShoutout&call/shoutout'))
      },
      {
        exact: true,
        path: '/transactions',
        component: lazy(() => import('src/views/transactions'))
      },
      {
        exact: true,
        path: '/refund_list',
        component: lazy(() => import('src/views/refundPayment'))
      },
      {
        exact: true,
        path: '/bank-list',
        component: lazy(() => import('src/views/bankDetail'))
      },
      {
        exact: true,
        path: '/payAmount',
        component: lazy(() => import('src/views/payToInfluencersAmount'))
      },
      {
        exact: true,
        path: '/cmspage',
        component: lazy(() => import('src/views/cmsPage'))
      },
      {
        exact: true,
        path: '/appversions',
        component: lazy(() => import('src/views/appVersion'))
      },
      {
        exact: true,
        path: '/topics',
        component: () => <Redirect to='/topics/list' />
      },
      {
        exact: true,
        path: '/topics/all',
        component: lazy(() => import('src/views/form'))
      },
      {
        exact: true,
        path: '/influencers',
        component: lazy(() => import('src/views/influencers'))
      },
      {
        exact: true,
        path: '/categories',
        component: lazy(() => import('src/views/categories'))
      },
      {
        exact: true,
        path: '/call-log',
        component: lazy(() => import('src/views/callLog'))
      },
      {
        exact: true,
        path: '/languages',
        component: () => <Redirect to="/languages/list" />
      },
      {
        exact: true,
        path: '/send_message',
        component: lazy(() => import('src/views/sendMessage'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/adminCommission',
        component: lazy(() => import('src/views/totalCommission'))
      },
      {
        exact: true,
        path: '/allTransaction',
        component: lazy(() => import('src/views/allTransaction'))
      },
      {
        exact: true,
        path: '/paymentsetting',
        component: lazy(() => import('src/views/paymentSetting'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
