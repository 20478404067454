import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import cookie from 'react-cookies';

import { isValidRoute } from 'src/helpers/methods';

const GuestGuard = ({ children }) => {
  const { logout, me } = useAuth();
  const history = useHistory();

  useEffect(() => {
    console.log("UseEffect Called");
    async function fetchData() {
      try {
        const userDetails = await me();
        console.log("userDetails: ", userDetails);
        const { pathname } = window.location;
        console.log(pathname, "========", isValidRoute(pathname));
        if ((pathname === '/login' || pathname === '/' || pathname === '') && isValidRoute(pathname)) {
          console.log("called");
          history.push('/dashboard/basic');
        }
      } catch (error) {
        const { pathname } = window.location;
        if (!(pathname === '/login' || pathname === '/' || pathname === '') && isValidRoute(pathname)) {
          window.localStorage.setItem('prevpath', pathname);
          logout();
        }
        logout();
      }
    }
    fetchData();
  }, []);
  
  // const session = cookie.load('session');
  // if (session && session!=='null') {
  //   const date_now = Date.now();
  //   const expired_at = Date.parse(session.expired_at);
  //   if (expired_at < date_now || !expired_at) {
  //     logout();
  //   }else{
  //     //history.push('/dashboard/basic');
  //   }
  // }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
