import * as actionTypes from "./actionTypes";

export const actions = {
  setEmail: data => ({
    type: actionTypes.SET_EMAIL,
    data
  })
};

const initialState = {
  email: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMAIL: {
      return {
        ...state,
        email: action.data
      };
    }
    default: return state
  }
};

export default reducer;
