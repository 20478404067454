import cookie from 'react-cookies'

const axios = require("axios");

const createAxios = (ctx = {}) => {
    const session = cookie.load('session')
    const config = { headers: { "x-interface": "Web" ,"Access-Control-Allow-Origin": "*","content-type":"application/json"}};
    /*if (ctx.req) {
      config.baseURL = ctx.req.gatewayUrl;
      config.headers = {
        ...(ctx.req.headers.cookie && { Cookie: ctx.req.headers.cookie })
      };*/
    // if (session) {
    //     config.headers = {
    //         ...(config && { "x-interface": "Web", session: session.id || session })
    //     };
    // }

    const axiosInstance = axios.create();

    const get = ({ url, params, headers }) =>
        axiosInstance.request({
            method: "GET",
            url,
            params,
            // headers
        });

    const post = ({ url, data ,headers}) =>
        axiosInstance.request({
            method: "POST",
            url,
            data,
            headers,
            // params
            // withCredentials: true
        });

    const put = ({ url, data, headers, callback, callback2 }) => {
        return axiosInstance.request({
            method: "PUT",
            url,
            data,
            headers,
            onUploadProgress: callback,
            cancelToken: callback2,
            withCredentials: true
        });
    };

    return {
        instance: axiosInstance,
        get,
        post,
        put
    };
};

export default createAxios;
