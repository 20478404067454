export const themeVars = {
    white: '#ffffff',
    primaryBlue: '#00a2f1',
    adminBlue: '#3366bb',
    primaryBlack: '#414141',
    black: '#000',
    darkBabyBlue: '#c8e8f7',
    babyBlue: '#e1f4fe',
    lightGrey: '#bdbdbd',
    darkGrey: '#757575',
    mediumGrey: '#9e9e9e',
    extraLightBabyBlue: '#ecf7fc',
    extraLightGrey: '#e0e0e0',
    extraExtraLightGreyBlue: '#fafbff',
    extraExtraLightGrey: '#f5f5f5',
    lightRed: '#ff5131',
    lightPink: '#fd558f',
    lightPurple: '#e254ff',
    lightDeepPurple: '#9d46ff',
    lightBlue: '#66d3ff',
    lightCyan: '#62ebff',
    lightTeal: '#5df2d6',
    red: '#d50000',
    redQuarterOpacity: '#d5000040',
    pink: '#c51162',
    purple: '#aa00ff',
    deepPurple: '#6200ea',
    cyan: '#00b8d4',
    teal: '#00bfa5',
    darkRed: '#9b0000',
    darkPink: '#8e0038',
    darkPurple: '#7200ca',
    darkDeepPurple: '#0a00b6',
    darkBlue: '#0074be',
    darkCyan: '#0088a3',
    darkTeal: '#008e76',
    lightGreen: '#5efc82',
    lightLime: '#e4ff54',
    lightYellow: '#ffff52',
    lightAmber: '#ffdd4b',
    lightOrange: '#ff9e40',
    lightDeepOrange: '#ff6434',
    lightBrown: '#6a4f4b',
    green: '#00c853',
    lime: '#aeea00',
    yellow: '#ffd600',
    amber: '#ffab00',
    orange: '#ff6d00',
    deepOrange: '#dd2c00',
    brown: '#3e2723',
    darkGreen: '#009624',
    darkLime: '#79b700',
    darkYellow: '#c7a500',
    darkAmber: '#c67c00',
    darkOrange: '#c43c00',
    darkDeepOrange: '#a30000',
    darkBrown: '#1b0000',

    //MuiSkeleton-root colors
    skeletonFeedPageTitle: '#D3D3D3',
    skeletonFeedCard: '#E0E0E0',

    unreadNotification: '#ffffff',

    // shadows
    // cardShadow: 'rgba(0, 162, 241, 0.122)',
    quesstCardShadow: "0 5px 10px 0 rgba(0, 162, 241, 0.12)",
    quesstAdminCardShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.12)",
    quesstFabButtonShadow: "0px 5px 10px rgba(33,33,33,0.12)",
    menuShadow: '#41414129',

    //backdrop
    backdropColor: '#41414180',


    arrowBorder: '#cee2ec1f',

    headerHeight: '84px',
    headerHeightMobile: '70px',
    headerHeightOriginal: '64px',
    headerHeightMobileOriginal: '50px',


    titleHeight: '60px',
    titleHeightNoSubheading: '36px',
    titleHeightMobile: '52px',
    titleHeightNoSubheadingMobile: '28px',


    newQuesstButton: '74px',
    newQuesstButtonNoSubheading: '50px',

    newQuesstButtonMobile: '66px',
    newQuesstButtonNoSubheadingMobile: '45px',

    newQuesstButtonTop: '130px',
    newQuesstButtonTopMobile: '111px',
    newQuesstButtonTopSub: '154px',
    newQuesstButtonTopMobileSub: '131px',

    // media breakpoints
    smallMobile: "360px",
    mobile: "640px",
    phablet: "1030px",
    tablet: "1400px",
    desktop: "1960px",

    // checkbox 
    heightCheckbox: '30px',
    widthCheckbox: '30px',
}


