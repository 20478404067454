import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

import useAuth from '../hooks/useAuth';
import { isValidRoute } from 'src/helpers/methods';

const AuthGuard = ({ children }) => {
  const history = useHistory(); 
  const { logout, me } = useAuth();

  useEffect(() => {
    async function fetchData() {
      try {
        const userDetails = await me();
        const { pathname } = window.location;
        if ((pathname === '/login' || pathname === '/' || pathname === '') && isValidRoute(pathname)) {
          history.push('/dashboard/basic');
        }
      } catch (error) {
        const { pathname } = window.location;
        if (!(pathname === '/login' || pathname === '/' || pathname === '') && isValidRoute(pathname)) {
          window.localStorage.setItem('prevpath', pathname);
          logout();
        }
        await logout();
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
