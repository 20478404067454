import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  ThemeProvider
} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';

const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const pathName = window.location.pathname + window.location.search;
  let routes_history = localStorage.getItem('routes_history')
  if (pathName !== '/404') {
    routes_history = routes_history ? routes_history.split(',') : [];
    const routes_history_length = routes_history.length;
    if (routes_history_length === 3) {
      if (routes_history[2] !== pathName) {
        routes_history[0] = routes_history[1]
        routes_history[1] = routes_history[2]
        routes_history[2] = pathName
      }
    }
    if (routes_history_length === 2) {
      if (routes_history[1] !== pathName) {
        routes_history[2] = pathName
      }
    }
    if (routes_history_length === 1) {
      if (routes_history[0] === pathName) {
        routes_history[1] = pathName
      }
    }
    if (routes_history_length === 0) {
      routes_history[0] = pathName
    }
  }
  // localStorage.setItem('routes_history', routes_history)

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <AuthProvider>
          <GlobalStyles />
          <ScrollReset />
          {renderRoutes(routes)}
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
