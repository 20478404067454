export const tooltipPosition = {
  BottomEnd: "bottom-end",
  BottomStart: "bottom-start",
  Bottom: "bottom",
  LeftEnd: "left-end",
  LeftStart: "left-start",
  Left: "left",
  RightEnd: "right-end",
  RightStart: "right-start",
  Right: "right",
  TopEnd: "top-end",
  TopStart: "top-start",
  Top: "top"
};

export const QuesstAdminLogo = `/static/assets/logo/svg/Quesst_En_Gray.svg`;
export const QuesstAdminLogoBetaHorizontal = `/static/callmi_logo.png`;
export const QuesstAdminLogoBetaVertical = `/static/water_mark_logo.png`;
export const QuesstAdminLogoWBetaHorizontal = `/static/water_mark_logo3.png`;
export const NoImage = `/static/noimage.png`;
