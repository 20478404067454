import * as actionTypes from "./actionTypes";

export const actions = {
  loginUserDetail: data => (    {
    type: actionTypes.LOGIN_USER_DETAIL,
    data
  }),

};

const initialState = {
  loginUserDetail:"",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER_DETAIL:
      return {
          ...state,
          loginUserDetail: action.data,
      };
  }

  return state;
};

export default reducer;
