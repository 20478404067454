import React, { Component } from "react";
import { StyledLoader } from "./styles";
import "./style.css";
import { themeVars } from 'src/utils/common';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      sizeSpeed = "medium-fast",
      color = themeVars.mediumGrey
    } = this.props;
    return (
      <StyledLoader sizeSpeed={sizeSpeed} color={color} >
        <div
          className={
            sizeSpeed === "small-slow"
              ? `lds-small-slow-ellipsis`
              : `lds-medium-fast-ellipsis`
          }
        >
          <div style={{ backgroundColor: color }} />
          <div style={{ backgroundColor: color }} />
          <div style={{ backgroundColor: color }} />
          <div style={{ backgroundColor: color }} />
        </div>
      </StyledLoader>
    );
  }
}

export default Loader;
