// Config
export const SNACKBAR_DATA = "SNACKBAR_DATA";

// User
export const LOGIN_USER_DETAIL = "LOGIN_USER_DETAIL";

// Email
export const SET_EMAIL = "SET_EMAIL";

//Datatable
export const SET_ADD_CONSULTANTS = "SET_ADD_CONSULTANTS";
export const SET_ADD_Modal = "SET_ADD_Modal";
export const SHOW_DETAIL_MODAL = "SHOW_DETAIL_MODAL";
export const SHOW_PAY_INFLUNCER = "SHOW_PAY_INFLUNCER";
export const SET_EDIT_USER = "SET_EDIT_USER";
export const DELETE_MODAL = "DELETE_MODAL";




