import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'src/redux';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
        <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

