import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { QuesstAdminLogoWBetaHorizontal } from 'src/utils/constants'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';
import { themeVars } from "src/utils/common";
import {
  mdiAccountCircle,
  mdiFormatListBulletedTriangle,
  mdiAccountGroupOutline,
  mdiGoogleCirclesCommunities,
  mdiCheck,
  mdiSoccerField,
  mdiMessageText,
  mdiFormatText,
  mdiPin,
  mdiViewDashboard,
  mdiCashRefund,
  mdiBank,
  mdiPhoneOutline,
  mdiCashMultiple,
  mdiCashFast,
  mdiCashMarker

} from '@mdi/js'
import getIconByType from "src/components/Icons";
import "react-perfect-scrollbar/dist/css/styles.css";

const sections = [
  {
    subheader: 'Dashboards',
    items: [
      {
        title: 'Basic Metrics',
        icon: mdiViewDashboard,
        href: '/dashboard/basic'
      },
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Show Users',
        icon: mdiAccountGroupOutline,
        href: '/users',
      },
      {
        title: 'Influencers',
        icon: mdiAccountCircle,
        href: '/influencers'
      },
      {
        title: 'Categories',
        icon: mdiFormatListBulletedTriangle,
        href: '/categories'
      },
      {
        title: 'Call Log',
        icon: mdiPhoneOutline,
        href: '/call-log'
      },
      {
        title: 'Done Shoutout & Call',
        icon: mdiCheck,
        items: [
          {
            title: 'Call',
            icon: mdiPhoneOutline,
            href: '/call'
          },
          {
            title: 'Shoutout',
            icon: mdiGoogleCirclesCommunities,
            href: '/shoutout'
          }
        ]
      },
      {
        title: 'Total Payments',
        icon: mdiSoccerField,
        href: '/transactions'
      },
      {
        title: 'Refund Payments',
        icon: mdiCashRefund,
        href: '/refund_list'
      },
      // {
      //   title: 'Total Commission',
      //   icon: mdiCashFast,
      //   href: '/adminCommission'
      // },
      // {
      //   title: 'All Transaction',
      //   icon: mdiCashMultiple,
      //   href: '/allTransaction'
      // },
      {
        title: 'Bank Detail',
        icon: mdiBank,
        href: '/bank-list'
      },
      {
        title: 'Pay Amount',
        icon: mdiPhoneOutline,
        href: '/payAmount'
      },
      {
        title: 'Send Message',
        icon: mdiMessageText,
        href: '/send_message'
      },
      // {
      //   title: 'PayMent Settings',
      //   icon: mdiCashMarker,
      //   href: '/paymentSetting'
      // },
      // {
      //   title: 'Cms Page',
      //   icon: mdiFormatText,
      //   href: '/cmspage'
      // },
      {
        title: 'App Versions',
        icon: mdiPhoneOutline,
        href: '/appversions'
      },
      // {
      //   title: 'Topics',
      //   icon: mdiPin,
      //   items: [
      //     {
      //       title: 'All Topics',
      //       href: '/topics/all'
      //     }
      //   ]
      // },
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const _user = localStorage.getItem('user');
  const { loginUserDetail } = useSelector(state => state.user);
  const classes = useStyles();
  const location = useLocation();
  const userss = JSON.parse(_user);
  const user = loginUserDetail ? loginUserDetail : userss
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: themeVars.white }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
            style={{borderBottom:"1px solid #e0e0e0"}}
          >
            <RouterLink to="/">
              <img
                alt="Logo"
                height='45'
                src={QuesstAdminLogoWBetaHorizontal}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/profile">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user ? user.photo : null}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/profile"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user ? user.name : null}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
