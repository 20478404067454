import styled from "styled-components";
import {themeVars} from 'src/utils/common'

export const StyledLoader = styled.div`
  ${props =>
    props.sizeSpeed === "small-slow"
      ? `

            .lds-small-slow-ellipsis div {
    position: absolute;
    top: 40%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props.color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

    `
      : `

            .lds-medium-fast-ellipsis div {
    position: absolute;
    top: 20%;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: '${themeVars.mediumGrey}';    
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    color:'${themeVars.mediumGrey}';
    border: "2px solid ${themeVars.mediumGrey}";
  }
`};

  ${props =>
    props.sizeSpeed === "small-slow"
      ? `

            .lds-small-slow-ellipsis div:nth-child(1) {
    left: 5px;
    animation: lds-small-slow-ellipsis1 1s infinite;
  }
  .lds-small-slow-ellipsis div:nth-child(2) {
    left: 5px;
    animation: lds-small-slow-ellipsis2 1s infinite;
  }
  .lds-small-slow-ellipsis div:nth-child(3) {
    left: 17.5px;
    animation: lds-small-slow-ellipsis2 1s infinite;
  }
  .lds-small-slow-ellipsis div:nth-child(4) {
    left: 30px;
    animation: lds-small-slow-ellipsis3 1s infinite;
  }
  @keyframes lds-small-slow-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-small-slow-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-small-slow-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }

    `
      : `

    .lds-medium-fast-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-medium-fast-ellipsis1 0.6s infinite;
  }
  .lds-medium-fast-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-medium-fast-ellipsis2 0.6s infinite;
  }
  .lds-medium-fast-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-medium-fast-ellipsis2 0.6s infinite;
  }
  .lds-medium-fast-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-medium-fast-ellipsis3 0.6s infinite;
  }
  @keyframes lds-medium-fast-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-medium-fast-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-medium-fast-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`};
`;
