import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import cookie from 'react-cookies';
import { userLogin, getUser, getLoggedInUserDetails, userLogout, verifyEmail } from "src/utils/api";
import SplashScreen from 'src/components/loader/SplashScreen';
import { useHistory } from 'react-router-dom';
import { userActions } from "src/redux/actions";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  me: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const login = async (email, password, keepMeLoggedIn) => {
    try {
      const { data } = await userLogin(email, password)
     await dispatch(userActions.loginUserDetail(data.data[0]));
      localStorage.setItem('user', JSON.stringify(data.data[0]));
      const isAuthenticated = true;
      dispatch({
        type: 'LOGIN',
        payload: {
          data,
          isAuthenticated
        }
      });
      return data;
    } catch (e) {
      throw e;
    }

  }

  const me = async () => {
    try {
      const _user = localStorage.getItem('user');
      const userdata = JSON.parse(_user)
      const { data } = await getLoggedInUserDetails(userdata.id, userdata.token);
      return data;
    } catch (e) {
      throw e;
    }

  }

  const logout = async () => {
    setSession(null);
    try {
      const _user = localStorage.getItem('user');
      const userdata = JSON.parse(_user)
      const { data } = await userLogout(userdata.id);
      if (data.success) {
        localStorage.removeItem('user');
      }
    } catch (error) {
    }
    dispatch({ type: 'LOGOUT' });
    history.push('/login');
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const session = cookie.load('session')
        if (session && session !== 'null') {
          setSession(session);

          const response = await getUser();
          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  // if (!state.isInitialised) {
  //   console.log("gggggggggggggg")
  //   return <SplashScreen />
  // }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        me
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;