import * as actionTypes from "./actionTypes";

export const actions = {
    setAddConsultants: data => ({
        type: actionTypes.SET_ADD_CONSULTANTS,
        data
    }),
    setAddModal: data => ({
        type: actionTypes.SET_ADD_Modal,
        data
    }),
    setDetailModal: data => ({
        type: actionTypes.SHOW_DETAIL_MODAL,
        data
    }),
    setPayInfluncer: data => ({
        type: actionTypes.SHOW_PAY_INFLUNCER,
        data
    }),
    setEditUsers: data => ({
        type: actionTypes.SET_EDIT_USER,
        data
    }),
    setDeleteModal: data => ({
        type: actionTypes.DELETE_MODAL,
        data
    }),
};

export const initialState = {
    addConsultantsModal: false,
    addModal: false,
    showDetailModal: false,
    payInfluncer: false,
    edituser: false,
    deleteModal: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SET_ADD_CONSULTANTS:
            return {
                ...state,
                addConsultantsModal: action.data,
            };

        case actionTypes.SHOW_DETAIL_MODAL:
            return {
                ...state,
                showDetailModal: action.data,
            };

        case actionTypes.SET_ADD_Modal:
            return {
                ...state,
                addModal: action.data,
            };

        case actionTypes.SHOW_PAY_INFLUNCER:
            return {
                ...state,
                payInfluncer: action.data,
            };
        case actionTypes.SET_EDIT_USER:
            return {
                ...state,
                edituser: action.data,
            };
            
        case actionTypes.DELETE_MODAL:
            return {
                ...state,
                deleteModal: action.data,
            };
        default:
            return state;
    }
};

export default reducer;
