import routes from "src/routes";

export const isValidRoute = pathname => {
    // let valid = false;
    // const _routes = routes;
    return routes.find((route)=>{
        if(route){
            return route.path === pathname
        }
    })
    // return valid;
}