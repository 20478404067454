import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { default as user } from "./userReducer";
import { default as tempEmail } from "./emailReducer";
import { default as config } from "./configReducer";
import { default as datatable } from "./datatableReducer";


const rootReducer = combineReducers({
  form: formReducer,
  tempEmail,
  user,
  config,
  datatable
});

export default rootReducer;
